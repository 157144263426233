// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
// components
import SummaryCard from "./components/SummaryCard";
import { CircularLoader, Page } from "src/components";
//hooks
import { useAppContext } from "src/hooks";
import { useEffect, useState } from "react";
import { _get_dashboard_stats } from "src/DAL/dashboard";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------
const DUMMY_DATA = [
  {
    color: "success",
    title: "Users",
    count: 714000,
    icon: "ant-design:android-filled",
  },
  {
    color: "info",
    title: "Sales",
    count: 4000,
    icon: "icon-park-outline:sales-report",
  },
  {
    color: "secondary",
    title: "Traffic",
    count: 3000,
    icon: "clarity:cloud-traffic-line",
  },
];
export default function Dashboard() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { _get_user_profile } = useAppContext();
  const [pageLoading, setPageLoading] = useState(true);
  const [dashboardStats, setDashboardStats] = useState({
    user_count: 0,
    total_tasks: 0,
    project_count: 0,
    workspace_count: 0,
  });
  const DUMMY_DATA = [
    {
      color: "success",
      title: "Users",
      count: dashboardStats.user_count,
      icon: "ant-design:android-filled",
    },
    {
      color: "info",
      title: "Projects",
      count: dashboardStats.project_count,
      icon: "icon-park-outline:sales-report",
    },
    {
      color: "secondary",
      title: "Workspaces",
      count: dashboardStats.workspace_count,
      icon: "carbon:workspace-import",
    },
    {
      color: "warning",
      title: "Tasks",
      count: dashboardStats.total_tasks,
      icon: "tdesign:task",
    },
  ];
  const getDashboardStats = async () => {
    try {
      const result = await _get_dashboard_stats();
      if (result.code == 200) {
        setDashboardStats(result.dashboard_stats);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error, " catched while geting dashboard stats");
    } finally {
      setPageLoading(false);
    }
  };
  useEffect(() => {
    getDashboardStats();
  }, []);

  return (
    <Page title="Dashboard">
      {pageLoading && (
        <div className="h-100 d-flex justify-content-between align-items-center">
          <CircularLoader />
        </div>
      )}
      {!pageLoading && (
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Hi, Welcome back
          </Typography>

          <Grid container spacing={3}>
            {DUMMY_DATA.map((item, i) => (
              <Grid key={i} item xs={12} sm={6} md={3}>
                <SummaryCard
                  color={item.color}
                  title={item.title}
                  count={item.count}
                  icon={item.icon}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </Page>
  );
}
