import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useEffect, useLayoutEffect, useState } from "react";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
} from "src/components";
import TableCustomHead from "src/components/TableCustomHead";
// mock
// import USERLIST from "../../_mock/user";
//
import { useNavigate } from "react-router-dom";
import TableToolbar from "src/components/TableToolbar";
import MoreMenu from "../../components/MoreMenu";
import { _get_users_list } from "src/DAL/users";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "plan", label: "Plan", alignRight: false },
  { id: "subscription", label: "Subscription", alignRight: false },
  // { id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function applySortFilter(array, query) {
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return array;
}

export default function Users() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [userList, setUserList] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const MENU_OPTIONS = [
    {
      label: "Edit plan",
      icon: "mdi:credit-card-edit",
      onClick: (item) => {
        navigate("/edit-user-plan", { state: { ...item } });
      },
    },
  ];
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = userList;
  // const filteredUsers = applySortFilter(userList, filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const getUsersList = async () => {
    setPageLoading(true);
    try {
      const result = await _get_users_list(page, rowsPerPage, {
        search: filterName,
      });
      if (result.code == 200) {
        setUserList(result.subscriber_list.all_subscribers);
        setUserCount(result.subscriber_list.all_subscribers_count);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error, " catched while fetching user list");
    } finally {
      setPageLoading(false);
    }
  };
  useEffect(() => {
    setUserList([]);
    setPageLoading(true);
    getUsersList();
  }, [page, filterName]);
  useEffect(() => {
    setPage(0);
  }, [rowsPerPage, filterName]);
  return (
    <Page title="User">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">Users</Typography>
        </Stack>

        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
        >
          <TableToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder="Search Users..."
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableCustomHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {userList
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        user_id,
                        first_name,
                        last_name,
                        email,
                        image,
                        subscription_status,
                        subscription,
                      } = row;

                      return (
                        <TableRow hover key={user_id}>
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                alt={first_name}
                                src={image ? s3baseUrl + image : ""}
                              />
                              <Typography variant="subtitle2" noWrap>
                                {first_name + " " + last_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">
                            {subscription
                              ? subscription
                              : "Free Plan (new user)"}
                          </TableCell>
                          {/* <TableCell align="left">
                            {isVerified ? "Yes" : "No"}
                          </TableCell> */}
                          <TableCell align="left">
                            {/* <Label
                              variant="outlined"
                              color={
                                (subscription_status === "deleted" &&
                                  "error") ||
                                "success"
                              }
                            >
                              {sentenceCase(
                                subscription_status
                                  ? subscription_status
                                  : "active"
                              )}
                            </Label> */}
                            <Label
                              variant="outlined"
                              color={
                                ((subscription_status === "deleted" ||
                                  subscription_status === "canceled") &&
                                  "error") ||
                                (subscription_status === "requires_action" &&
                                  "warning") ||
                                "success"
                              }
                            >
                              {sentenceCase(
                                subscription_status
                                  ? subscription_status
                                  : "active"
                              )}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            <MoreMenu row={row} menuOptions={MENU_OPTIONS} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {pageLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <div className="d-flex justify-content-center align-items-center w-100">
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {isUserNotFound && !pageLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={userCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
