import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useLayoutEffect, useState } from "react";
import {
  _edit_website_settings,
  _get_website_settings,
} from "src/DAL/websiteSettings";
import { Page } from "src/components";

function WebsiteSettings() {
  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    support_email: "",
    privacy_policy: "",
    stripe_public_key: "",
    stripe_secret_key: "",
    terms_and_conditions: "",
  });
  const [editLoading, setEditLoading] = useState(false);
  const handleChange = (e) => {
    setFormInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const getWebsiteSettings = async () => {
    try {
      const result = await _get_website_settings();
      if (result.code == 200) {
        setFormInputs((prev) => {
          return {
            ...prev,
            privacy_policy: result.setting.privacy_policy,
            stripe_public_key: result.setting.stripe_public_key,
            stripe_secret_key: result.setting.stripe_secret_key,
            support_email: result.setting.support_email,
            terms_and_conditions: result.setting.terms_and_conditions,
          };
        });
      }
    } catch (error) {
      console.log(error, " catched while getting website settings");
    }
  };
  const editWebsiteSettings = async () => {
    try {
      setEditLoading(true);
      const result = await _edit_website_settings(formInputs);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
    } catch (error) {
      console.log(error, " catched while updating settings");
    } finally {
      setEditLoading(false);
    }
  };
  useLayoutEffect(() => {
    getWebsiteSettings();
  }, []);
  return (
    <Page title="Settings">
      <Container maxWidth="xl">
        <Typography variant="h4" gutterBottom>
          Website Settings
        </Typography>
      </Container>
      <Container sx={{ my: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Stripe Public Key"
                name="stripe_public_key"
                variant="outlined"
                onChange={handleChange}
                value={formInputs.stripe_public_key}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Stripe Secret Key"
                variant="outlined"
                name="stripe_secret_key"
                onChange={handleChange}
                value={formInputs.stripe_secret_key}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Support Email"
                variant="outlined"
                name="support_email"
                onChange={handleChange}
                value={formInputs.support_email}
              />
            </FormControl>
          </Grid>
        </Grid>
        <LoadingButton
          loading={editLoading}
          sx={{ mt: 2 }}
          variant="contained"
          onClick={editWebsiteSettings}
        >
          Update
        </LoadingButton>
      </Container>
    </Page>
  );
}

export default WebsiteSettings;
