import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const AddEditCurrencyDialogue = ({
  open,
  handleClose,
  formType,
  targetData,
  handleAddUpdate,
}) => {
  const [formData, setFormData] = useState({
    full_form: "",
    short_form: "",
    symbol: "",
    status: "true",
  });

  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    setIsSubmiting(false);
    if (formType === "update" && targetData) {
      setFormData({
        full_form: targetData.full_form || "",
        short_form: targetData.short_form || "",
        symbol: targetData.symbol || "",
        status: targetData.status ? "true" : "false",
      });
    } else {
      setFormData({
        full_form: "",
        short_form: "",
        symbol: "",
        status: "true",
      });
    }
  }, [formType, targetData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmiting(true);
    await handleAddUpdate(formData);
    setIsSubmiting(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {formType === "add" ? "Add Currency" : "Update Currency"}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className="pt-0">
          <TextField
            label="Currency"
            name="full_form"
            value={formData.full_form}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Symbol"
            name="symbol"
            value={formData.symbol}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Currency Code"
            name="short_form"
            value={formData.short_form}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            select
            required
            label="Status"
            name="status"
            value={formData.status}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value="true">Active</MenuItem>
            <MenuItem value="false">Inactive</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={isSubmiting} type="submit" color="primary">
            {formType === "add" ? "Add" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEditCurrencyDialogue;
