import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { addBanner, getLandingPage } from "src/DAL/landingPage";
import { _upload_file } from "src/DAL/uploads";
import { CircularLoader, Page, RichEditor } from "src/components";
import { s3baseUrl } from "src/config/config";

function Banner() {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [bannerData, setBannerData] = useState();
  const getLandingPageData = async () => {
    try {
      const result = await getLandingPage();
      if (result.code == 200) {
        setBannerData({
          ...result.data.banner,
          file: null,
          blob: "",
        });
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadingBtn(true);
      let data = bannerData;
      if (bannerData.file) {
        const formData = new FormData();
        formData.append("directory", "images/");
        formData.append("upload_file", bannerData.file);
        const imgResult = await _upload_file(formData);
        if (imgResult.code == 200) {
          data["banner_image"] = imgResult.path;
        }
      }
      delete data.file;
      delete data.blob;
      const result = await addBanner(data);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setBannerData(result.data.banner);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingBtn(false);
    }
  };
  useEffect(() => {
    getLandingPageData();
  }, []);
  if (loading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Banner">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">Banner</Typography>
        </Stack>
        <form action="" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <RichEditor
                  value={bannerData.banner_title}
                  placeHolder="Banner Title"
                  setValue={(val) =>
                    setBannerData((prev) => {
                      return { ...prev, banner_title: val };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Banner button text"
                  value={bannerData.banner_button.text}
                  onChange={(e) =>
                    setBannerData((prev) => {
                      return {
                        ...prev,
                        banner_button: {
                          ...prev.banner_button,
                          text: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Banner button link"
                  value={bannerData.banner_button.link}
                  onChange={(e) =>
                    setBannerData((prev) => {
                      return {
                        ...prev,
                        banner_button: {
                          ...prev.banner_button,
                          link: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Banner video button text"
                  value={bannerData.banner_video_button.text}
                  onChange={(e) =>
                    setBannerData((prev) => {
                      return {
                        ...prev,
                        banner_video_button: {
                          ...prev.banner_video_button,
                          text: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Banner video button link"
                  value={bannerData.banner_video_button.link}
                  onChange={(e) =>
                    setBannerData((prev) => {
                      return {
                        ...prev,
                        banner_video_button: {
                          ...prev.banner_video_button,
                          link: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {(bannerData.blob || bannerData.banner_image) && (
                <img
                  style={{
                    width: "17rem",
                    height: "9rem",
                    // marginLeft: "27rem",
                    marginTop: "1rem",
                  }}
                  src={
                    bannerData.blob
                      ? bannerData.blob
                      : s3baseUrl + bannerData.banner_image
                  }
                  alt="svg"
                />
              )}

              <label htmlFor="icon-button-file">
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  value=""
                  name="logo"
                  onChange={(e) =>
                    setBannerData((prev) => {
                      return {
                        ...prev,
                        file: e.target.files[0],
                        blob: URL.createObjectURL(e.target.files[0]),
                      };
                    })
                  }
                />
                <Button
                  aria-label="upload picture"
                  component="span"
                  className="upload-button mt-3 "
                >
                  {bannerData.banner_image !== ""
                    ? "Change Image"
                    : "Upload Image"}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              <div className="text-end mt-3">
                <LoadingButton
                  loading={loadingBtn}
                  variant="contained"
                  type="submit"
                >
                  Update
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
}

export default Banner;
