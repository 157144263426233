import { invokeApi } from "src/utils";
export const _get_transactions_list = (page, limit, data) => {
  const requestObj = {
    path: `api/admin/transaction_list?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
