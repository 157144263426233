import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  addStories,
  addSubscriberData,
  getLandingPage,
} from "src/DAL/landingPage";
import { _upload_file } from "src/DAL/uploads";
import {
  CircularLoader,
  Iconify,
  MuiDialog,
  Page,
  RichEditor,
} from "src/components";
import { s3baseUrl } from "src/config/config";

function SubscriberData() {
  const { enqueueSnackbar } = useSnackbar();
  const [subscriberData, setSubscriberData] = useState();
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [addMenuitemDialogOpen, setAddMenuitemDialogOpen] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [newMenuItem, setNewMenuItem] = useState({
    text: "",
    link: "",
  });
  const [deleteLogoDialog, setDeleteLogoDialog] = useState(false);
  const openDelete = () => {
    setDeleteLogoDialog(true);
  };
  const closeDelete = () => {
    setDeleteLogoDialog(false);
    setEditIndex();
  };
  const confirmDelete = () => {
    setSubscriberData((prev) => {
      return {
        ...prev,
        menu_items: prev.menu_items.filter((item, i) => i !== editIndex),
      };
    });
    closeDelete();
  };
  const openAddItemDialog = () => {
    setAddMenuitemDialogOpen(true);
  };
  const closeAddItemDialog = () => {
    setAddMenuitemDialogOpen(false);
    setNewMenuItem({
      text: "",
      link: "",
    });
    setIsEditing(false);
    setEditIndex();
  };
  const getLandingPageData = async () => {
    try {
      const result = await getLandingPage();
      if (result.code == 200) {
        setSubscriberData(result.data.subscribe_data);
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleAddData = () => {
    setSubscriberData((prev) => {
      return {
        ...prev,
        menu_items: [...prev.menu_items, newMenuItem],
      };
    });
    closeAddItemDialog();
  };
  const handleEditData = () => {
    setSubscriberData((prev) => {
      let temp_menu = prev.menu_items;
      temp_menu[editIndex] = newMenuItem;
      return {
        ...prev,
        menu_items: [...temp_menu],
      };
    });
    closeAddItemDialog();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadingBtn(true);
      let data = subscriberData;
      let logos = data.menu_items;
      for (let i = 0; i < logos.length; i++) {
        if (logos[i]._id) {
          delete logos[i]._id;
        }
      }
      data["menu_items"] = logos;
      const result = await addSubscriberData(data);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setSubscriberData(result.data.subscribe_data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingBtn(false);
    }
  };
  useEffect(() => {
    getLandingPageData();
  }, []);
  if (loading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Suscriber Data">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">Suscriber Data</Typography>
        </Stack>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RichEditor
                placeholder="Title"
                value={subscriberData.title}
                setValue={(val) =>
                  setSubscriberData((prev) => {
                    return { ...prev, title: val };
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Subscribe Button Text"
                  value={subscriberData.subscribe_button.text}
                  onChange={(e) =>
                    setSubscriberData((prev) => {
                      return {
                        ...prev,
                        subscribe_button: {
                          ...prev.subscribe_button,
                          text: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Subscribe Button link"
                  value={subscriberData.subscribe_button.link}
                  onChange={(e) =>
                    setSubscriberData((prev) => {
                      return {
                        ...prev,
                        subscribe_button: {
                          ...prev.subscribe_button,
                          link: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Menu Items Title"
                  value={subscriberData.menu_items_title}
                  onChange={(e) =>
                    setSubscriberData((prev) => {
                      return {
                        ...prev,
                        menu_items_title: e.target.value,
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} mt={5}>
              <Button onClick={openAddItemDialog}>Add Menu Item</Button>
            </Grid>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Menu Item Text</TableCell>
                    <TableCell>Menu Item Link</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriberData?.menu_items?.map((val, i) => {
                    return (
                      <>
                        <TableRow>
                          <TableCell>{val.text}</TableCell>
                          <TableCell>{val.link}</TableCell>
                          <TableCell>
                            <Iconify
                              onClick={() => {
                                setIsEditing(true);
                                setEditIndex(i);
                                setNewMenuItem(val);
                                openAddItemDialog();
                              }}
                              style={{ color: "green", cursor: "pointer" }}
                              icon={"fa-regular:edit"}
                            />
                          </TableCell>
                          <TableCell>
                            <Iconify
                              onClick={() => {
                                setEditIndex(i);
                                openDelete();
                              }}
                              style={{ color: "red", cursor: "pointer" }}
                              icon={"ic:baseline-delete"}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              <div className="text-end pb-4">
                <LoadingButton
                  loading={loadingBtn}
                  variant="contained"
                  type="submit"
                >
                  Submit
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Dialog
        open={addMenuitemDialogOpen}
        onClose={closeAddItemDialog}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>{isEditing ? "Edit Menu Item" : "Add Menu Item"}</p>
            <p onClick={closeAddItemDialog}>
              <Iconify icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid>
            <>
              <div>
                <form
                  className="gap-3 mt-3"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (isEditing) {
                      handleEditData();
                    } else {
                      handleAddData();
                    }
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Menu Item Text"
                          required
                          // style={{ width: "48%" }}
                          onChange={(e) =>
                            setNewMenuItem((prev) => {
                              return {
                                ...prev,
                                text: e.target.value,
                              };
                            })
                          }
                          value={newMenuItem.text}
                          name="title"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Menu Item link"
                          required
                          // style={{ width: "48%" }}
                          onChange={(e) =>
                            setNewMenuItem((prev) => {
                              return {
                                ...prev,
                                link: e.target.value,
                              };
                            })
                          }
                          value={newMenuItem.link}
                          name="title"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <div className="text-end mt-3">
                    <Button
                      variant="contained"
                      type="submit"
                      // onClick={(e) => HandleEditData(e)}
                    >
                      {isEditing ? "Edit" : "Add"}
                    </Button>
                  </div>
                </form>
              </div>
            </>
          </Grid>
        </DialogContent>
      </Dialog>
      <MuiDialog
        open={deleteLogoDialog}
        onToggle={closeDelete}
        title="Delete step"
        message="Are you sure you want to delete this step?"
        onAgree={confirmDelete}
      />
    </Page>
  );
}

export default SubscriberData;
