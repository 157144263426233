import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { addBrandLogo, getLandingPage } from "src/DAL/landingPage";
import { _upload_file } from "src/DAL/uploads";
import {
  CircularLoader,
  Iconify,
  MuiDialog,
  Page,
  RichEditor,
} from "src/components";
import { s3baseUrl } from "src/config/config";

function BrandLogo() {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [brandLogoData, setBrandLogoData] = useState();
  const [selectedindex, setSelectedindex] = useState();
  const [deleteLogoDialog, setDeleteLogoDialog] = useState(false);
  const openDelete = () => {
    setDeleteLogoDialog(true);
  };
  const closeDelete = () => {
    setDeleteLogoDialog(false);
    setSelectedindex();
  };
  const confirmDelete = () => {
    setBrandLogoData((prev) => {
      return {
        ...prev,
        brand_logo: prev.brand_logo.filter((logo, i) => i !== selectedindex),
      };
    });
    closeDelete();
  };
  const getLandingPageData = async () => {
    try {
      const result = await getLandingPage();
      if (result.code == 200) {
        setBrandLogoData({
          ...result.data.brand_logo,
          brand_logo: result.data.brand_logo.brand_logo.map((item) => {
            return { ...item, file: null, blob: "" };
          }),
        });
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleDragEnd = (results) => {
    if (!results.destination) return;
    let tempLogos = [...brandLogoData.brand_logo];
    let [selectedLogo] = tempLogos.splice(results.source.index, 1);

    tempLogos.splice(results.destination.index, 0, selectedLogo);
    console.log(results, "saftyfdyt", selectedLogo);
    setBrandLogoData((prev) => {
      return {
        ...prev,
        brand_logo: tempLogos,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadingBtn(true);
      let data = brandLogoData;
      let logos = data.brand_logo;
      for (let i = 0; i < logos.length; i++) {
        if (logos[i].file) {
          const formData = new FormData();
          formData.append("directory", "images/");
          formData.append("upload_file", logos[i].file);
          const imgResult = await _upload_file(formData);
          if (imgResult.code == 200) {
            logos[i]["image"] = imgResult.path;
          }
        }
        delete logos[i].file;
        delete logos[i].blob;
        if (logos[i]._id) {
          delete logos[i]._id;
        }
      }
      data["brand_logo"] = logos;
      const result = await addBrandLogo(data);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setBrandLogoData(result.data.brand_logo);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingBtn(false);
    }
  };
  useEffect(() => {
    getLandingPageData();
  }, []);
  if (loading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Brand Logo">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">Brand Logo</Typography>
        </Stack>
        <form action="" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RichEditor
                value={brandLogoData.description}
                setValue={(val) =>
                  setBrandLogoData((prev) => {
                    return { ...prev, description: val };
                  })
                }
              />
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <label htmlFor="icon-button-file">
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  value=""
                  name="logo"
                  onChange={(e) =>
                    setBrandLogoData((prev) => {
                      return {
                        ...prev,
                        brand_logo: [
                          ...prev.brand_logo,
                          {
                            file: e.target.files[0],
                            blob: URL.createObjectURL(e.target.files[0]),
                            image: "",
                          },
                        ],
                      };
                    })
                  }
                />
                <Button
                  aria-label="upload picture"
                  component="span"
                  className="upload-button mt-3 "
                >
                  Upload Logo
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
                <Droppable droppableId="dropable1">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {brandLogoData.brand_logo.map((val, index) => (
                        <Draggable
                          draggableId={`dragable-${index}`}
                          index={index}
                          key={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              onClick={() => setSelectedindex(index)}
                              className={`${
                                snapshot.isDragging ? "shadow" : null
                              } row border m-1 mt-2  px-1 py-2 align-items-center`}
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                background: snapshot.isDragging
                                  ? "white"
                                  : null,
                              }}
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                <div {...provided.droppableProps}>
                                  <Iconify
                                    icon="ic:baseline-drag-handle"
                                    size={25}
                                  />
                                </div>
                                <img
                                  style={{
                                    width: "4rem",
                                    height: "3rem",
                                  }}
                                  src={
                                    val.blob ? val.blob : s3baseUrl + val.image
                                  }
                                  alt=""
                                />

                                <div className="d-flex">
                                  <IconButton
                                    sx={{ marginRight: "5rem" }}
                                    onClick={() => {
                                      setSelectedindex(index);
                                      console.log(
                                        document.getElementById("edit-label")
                                      );
                                      document
                                        .getElementById("edit-label")
                                        .click();
                                    }}
                                  >
                                    <Iconify
                                      style={{
                                        color: "green",
                                        cursor: "pointer",
                                        pointerEvents: "none",
                                      }}
                                      icon={"fa-regular:edit"}
                                    />
                                  </IconButton>

                                  <IconButton
                                    onClick={() => {
                                      setSelectedindex(index);
                                      openDelete();
                                    }}
                                  >
                                    <Iconify
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      icon={"ic:baseline-delete"}
                                    />
                                  </IconButton>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
            <Grid item xs={12}>
              <div className="text-end mt-3">
                <LoadingButton
                  loading={loadingBtn}
                  variant="contained"
                  type="submit"
                >
                  Update
                </LoadingButton>
              </div>
            </Grid>
            <label htmlFor="icon-button-edit" id="edit-label">
              <input
                style={{ display: "none" }}
                accept="image/*"
                id="icon-button-edit"
                type="file"
                value=""
                onChange={(e) =>
                  setBrandLogoData((prev) => {
                    let temp = prev.brand_logo;
                    console.log(temp, "acaskjcasicvjhashcas", selectedindex);
                    temp.splice(selectedindex, 1, {
                      file: e.target.files[0],
                      blob: URL.createObjectURL(e.target.files[0]),
                      image: "",
                    });
                    console.log(temp, "acaskjcasicvjhashcas");
                    return {
                      ...prev,
                      brand_logo: [...temp],
                    };
                  })
                }
              />
            </label>
          </Grid>
        </form>
      </Container>
      <MuiDialog
        open={deleteLogoDialog}
        onToggle={closeDelete}
        title="Delete brand logo"
        message="Are you sure you want to delete this logo?"
        onAgree={confirmDelete}
      />
    </Page>
  );
}

export default BrandLogo;
