import {
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { _change_user_plan, _get_user_detail } from "src/DAL/users";
import { Iconify, Page, Scrollbar } from "src/components";
import PaymentPlanCard from "./components/PaymentPlanCard";
import "./css/style.css"; // Your custom CSS
import { Waves } from "src/assets";
import { useSnackbar } from "notistack";

function EditUserPlan() {
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const stateData = state;
  console.log(stateData, "stateDataaaaa");
  const [userDetails, setUserDetails] = useState([]);
  console.log(userDetails, "plandetailllllls");
  const [pageLoading, setPageLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState("");
  const navigate = useNavigate();

  const getUserDetails = async () => {
    try {
      setPageLoading(true);
      const result = await _get_user_detail();
      if (result.code == 200) {
        console.log(result, "resulttttttttttt");
        setUserDetails(result);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        navigate(-1);
      }
    } catch (error) {
      console.log(error, " catched while fetching user details");
      enqueueSnackbar("Something went wrong", { variant: "error" });
    } finally {
      setPageLoading(false);
    }
  };

  const onButtonClick = async (plan) => {
    try {
      setButtonLoading(plan?._id);
      const req_obj = {
        user_id: state.user_id,
        plan_id: plan._id,
      };
      console.log(req_obj, "reqObjjjjjj");
      const result = await _change_user_plan(req_obj);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        getUserDetails();
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      console.log(error, " occured while changing user plan");
    } finally {
      setButtonLoading("");
    }
  };

  useEffect(() => {
    if (!state) {
      navigate("/");
    } else {
      getUserDetails();
    }
  }, []);

  return (
    <Page title="Edit User Plan">
      <>
        {pageLoading && (
          <div
            className="w-100 d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <CircularProgress />
          </div>
        )}
        {!pageLoading && (
          <>
            <Container maxWidth="xl" sx={{ overflow: "hidden" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent=""
                mb={4.5}
              >
                <IconButton onClick={() => navigate(-1)}>
                  <Iconify icon="ic:round-arrow-back" />
                </IconButton>
                <Typography variant="h4">Update User Plan</Typography>
              </Stack>
              <Grid container spacing={2} sx={{ mt: 4 }}>
                {userDetails?.subscription_plan?.map((plan) => (
                  <Grid item xs={12} sm={6} md={4} mb={5} key={plan._id}>
                    <PaymentPlanCard
                      plan={plan}
                      onClick={onButtonClick}
                      user={userDetails.customer}
                      buttonLoading={buttonLoading}
                    />
                  </Grid>
                ))}
              </Grid>
            </Container>
          </>
        )}
      </>
    </Page>
  );
}

export default EditUserPlan;
