import { LoadingButton } from "@mui/lab";
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { CircularLoader, Page } from "src/components";
import { siteSettingApi, updateStripeApi } from "src/DAL/websiteSettings";

function StripeSettings() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [inputs, setInputs] = useState({
    sandbox_publish_key: "Active Campaign",
    sandbox_secret_key: "",
    live_publish_key: "",
    live_secret_key: "",
    mode: "",
  });

  const siteSettingData = async () => {
    setIsLoading(true);
    const result = await siteSettingApi();
    console.log(result, "shgfsdfjds");
    if (result.code == 200) {
      setInputs(result?.admin_configuration?.stripe_configuration);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const updateStripeKeys = async (e) => {
    setIsLoading(true);
    let postData = {
      sandbox_publish_key: inputs.sandbox_publish_key,
      sandbox_secret_key: inputs.sandbox_secret_key,
      live_publish_key: inputs.live_publish_key,
      live_secret_key: inputs.live_secret_key,
      mode: inputs.mode,
    };
    const result = await updateStripeApi(postData);
    if (result.code === 200) {
      enqueueSnackbar("Stripe setting updated successfully", {
        variant: "success",
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar("Stripe setting not updated successfully", {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    siteSettingData();
  }, []);

  if (isLoading) {
    return (
      <div>
        <CircularLoader />
      </div>
    );
  }

  return (
    <>
      <Page title="Stripe Settings">
        <Container maxWidth="xl">
          <Typography variant="h4" gutterBottom>
            Stripe Settings
          </Typography>
        </Container>
        <Container sx={{ my: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Sandbox Publish Key"
                  name="sandbox_publish_key"
                  variant="outlined"
                  onChange={handleChange}
                  value={inputs.sandbox_publish_key}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Sandbox Secret Key"
                  variant="outlined"
                  name="sandbox_secret_key"
                  onChange={handleChange}
                  value={inputs.sandbox_secret_key}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Live Publish Key"
                  variant="outlined"
                  name="live_publish_key"
                  onChange={handleChange}
                  value={inputs.live_publish_key}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Live Secrete Key"
                  variant="outlined"
                  name="live_secret_key"
                  onChange={handleChange}
                  value={inputs.live_secret_key}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="asalabels">Stripe Mode</InputLabel>
                <Select
                  id="asalabels"
                  label="Clients Allowed"
                  onChange={handleChange}
                  name="mode"
                  value={inputs.mode}
                >
                  <MenuItem value="live">Live</MenuItem>
                  <MenuItem value="sandbox">Sandbox</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <LoadingButton
            variant="contained"
            sx={{ mt: 2 }}
            loading={isLoading}
            onClick={updateStripeKeys}
          >
            Update
          </LoadingButton>
        </Container>
      </Page>
    </>
  );
}

export default StripeSettings;
