import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Button,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  SearchNotFound,
  MuiDialog,
} from "src/components";
import TableCustomHead from "src/components/TableCustomHead";
// mock
// import plansList from "../../_mock/user";
//
import { useNavigate } from "react-router-dom";
import TableToolbar from "src/components/TableToolbar";
import MoreMenu from "../../components/MoreMenu";
import { _get_users_list } from "src/DAL/users";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import {
  _delete_payment_plan,
  _get_payment_plans,
} from "src/DAL/payment_plans";
import { get_currency_list } from "src/DAL/currency";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "" },
  { id: "name", label: "Plan Name", alignRight: false },
  { id: "plan_type", label: "Plan Type", alignRight: false },
  { id: "price", label: "Plan Price", alignRight: false },
  { id: "dashboard", label: "Dashboard Analytic", alignRight: false },
  { id: "support", label: "Email Support", alignRight: false },
  { id: "goole_calendar", label: "Google Calendar", alignRight: false },
  { id: "worksapces", label: "Workspaces", alignRight: false },
  { id: "projects", label: "Projects", alignRight: false },
  { id: "team", label: "Team", alignRight: false },
  { id: "clients", label: "Clients", alignRight: false },
  { id: "chat", label: "Chat", alignRight: false },
  { id: "storage", label: "Storage", alignRight: false },
  { id: "order", label: "Order", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  // { id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function applySortFilter(array, query) {
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return array;
}

export default function PaymentPlans() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [plansList, setPlansList] = useState([]);
  const [plansCount, setPlansCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);

  const [targetPlan, setTargetPlan] = useState({});
  const [deleteDialog, setDeleteDialog] = useState(false);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "ri:edit-line",
      onClick: (item) => {
        navigate("/plans/edit-plan", { state: { ...item } });
      },
    },
    {
      label: "Delete",
      icon: "ic:baseline-delete",
      onClick: (item) => {
        setDeleteDialog(true);
        setTargetPlan(item);
      },
    },
  ];

  const handleAgreeDeletePlan = async () => {
    setPageLoading(true);
    setDeleteDialog(false);
    const resp = await _delete_payment_plan(targetPlan._id);
    if (resp.code == 200) {
      getPlansList();
      enqueueSnackbar(resp.message, { variant: "success" });
    } else {
      setPageLoading(true);
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - plansList.length) : 0;

  const filteredUsers = applySortFilter(plansList, filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const getPlansList = async () => {
    setPageLoading(true);
    try {
      const result = await _get_payment_plans();
      if (result.code == 200) {
        setPlansList(result.subscription_plan);
        setPlansCount(result.subscription_plan.length);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error, " catched while fetching user list");
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    setPlansList([]);
    setPageLoading(true);
    getPlansList();
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage]);

  return (
    <>
      <MuiDialog
        open={deleteDialog}
        onToggle={() => {
          setDeleteDialog(false);
        }}
        title="Delete Payment Plan"
        message={`Are you sure you want to delete "${targetPlan.name}" plan?`}
        onAgree={() => {
          handleAgreeDeletePlan();
        }}
      />
      <Page title="Plans">
        <Container maxWidth="xl">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={4.5}
          >
            <Typography variant="h4">Payment Plans</Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/plans/add-plan")}
            >
              Add New Plan
            </Button>
          </Stack>

          <Card
            sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
          >
            {/* <TableToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder="Search Users..."
          /> */}

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableCustomHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    {!pageLoading &&
                      filteredUsers
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const {
                            _id,
                            name,
                            plan_type,
                            price,
                            dashboard_analytic,
                            email_support,
                            google_calendar_attachment,
                            workspace_allow,
                            project_allow,
                            team_member_allow,
                            client_allow,
                            real_time_chat,
                            storage_allow,
                            status,
                            order,
                            plan_currency,
                            currency_symbol,
                          } = row;

                          return (
                            <TableRow hover key={_id}>
                              <TableCell align="left">
                                <MoreMenu
                                  row={row}
                                  menuOptions={MENU_OPTIONS}
                                />
                              </TableCell>
                              <TableCell>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography variant="subtitle2" noWrap>
                                    {name
                                      ? name.charAt(0).toUpperCase() +
                                        name.slice(1)
                                      : "-"}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                {plan_type
                                  ? plan_type.charAt(0).toUpperCase() +
                                    plan_type.slice(1)
                                  : "-"}
                              </TableCell>
                              <TableCell align="left">
                                <Typography variant="subtitle2" noWrap>
                                  {`${currency_symbol ? currency_symbol : "$"}${
                                    price ? price : "0"
                                  }`}
                                </Typography>
                              </TableCell>
                              {/* <TableCell align="left">
                            {isVerified ? "Yes" : "No"}
                          </TableCell> */}
                              <TableCell align="left">
                                <Label
                                  variant="outlined"
                                  color={
                                    (dashboard_analytic.toLowerCase() ===
                                      "no" &&
                                      "error") ||
                                    "success"
                                  }
                                >
                                  {sentenceCase(dashboard_analytic)}
                                </Label>
                              </TableCell>
                              <TableCell align="left">
                                {email_support}
                              </TableCell>
                              <TableCell align="left">
                                <Label
                                  variant="filled"
                                  color={
                                    (google_calendar_attachment.toLowerCase() ===
                                      "no" &&
                                      "error") ||
                                    "success"
                                  }
                                >
                                  {sentenceCase(google_calendar_attachment)}
                                </Label>
                              </TableCell>
                              <TableCell align="left">
                                {workspace_allow}
                              </TableCell>
                              <TableCell align="left">
                                {project_allow}
                              </TableCell>
                              <TableCell align="left">
                                {team_member_allow}
                              </TableCell>
                              <TableCell align="left">{client_allow}</TableCell>
                              <TableCell align="left">
                                <Label
                                  variant="ghost"
                                  color={
                                    (real_time_chat.toLowerCase() === "no" &&
                                      "error") ||
                                    "success"
                                  }
                                >
                                  {sentenceCase(real_time_chat)}
                                </Label>
                              </TableCell>
                              <TableCell align="left">
                                {storage_allow}
                              </TableCell>
                              <TableCell align="left">
                                {order ? order : "-"}
                              </TableCell>
                              <TableCell align="left">
                                <Label
                                  variant="ghost"
                                  color={(!status && "error") || "success"}
                                >
                                  {status ? "Active" : "In-Active"}
                                </Label>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                  </TableBody>
                  {pageLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <div className="d-flex justify-content-center align-items-center w-100">
                            <CircularProgress />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {isUserNotFound && !pageLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={14} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[50, 100, 150]}
              component="div"
              count={plansCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
}
