import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { addHeader, getLandingPage } from "src/DAL/landingPage";
import { _upload_file } from "src/DAL/uploads";
import { CircularLoader, Iconify, MuiDialog, Page } from "src/components";
import { s3baseUrl } from "src/config/config";

function Header() {
  const [headerData, setHeaderData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [addMenuitemDialogOpen, setAddMenuitemDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedindex, setSelectedindex] = useState();
  const [editIndex, setEditIndex] = useState();
  const [newMenuItem, setNewMenuItem] = useState({
    title: "",
    link: "",
  });
  const [loading, setLoading] = useState(true);
  const [deleteLogoDialog, setDeleteLogoDialog] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const openDelete = () => {
    setDeleteLogoDialog(true);
  };
  const closeDelete = () => {
    setDeleteLogoDialog(false);
    setSelectedindex();
  };
  const openAddItemDialog = () => {
    setAddMenuitemDialogOpen(true);
  };
  const closeAddItemDialog = () => {
    setAddMenuitemDialogOpen(false);
    setNewMenuItem({
      title: "",
      link: "",
    });
    setIsEditing(false);
  };
  const confirmDelete = () => {
    setHeaderData((prev) => {
      return {
        ...prev,
        menu_items: prev.menu_items.filter((item, i) => i !== selectedindex),
      };
    });
    closeDelete();
  };
  const getLandingPageData = async () => {
    try {
      const result = await getLandingPage();
      if (result.code == 200) {
        setHeaderData({ ...result.data.header, file: null, blob: "" });
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleAddData = () => {
    setHeaderData((prev) => {
      return {
        ...prev,
        menu_items: [...prev.menu_items, newMenuItem],
      };
    });
    closeAddItemDialog();
  };
  const handleEditData = () => {
    setHeaderData((prev) => {
      let temp_menu = prev.menu_items;
      temp_menu[editIndex] = newMenuItem;
      return {
        ...prev,
        menu_items: [...temp_menu],
      };
    });
    closeAddItemDialog();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadingBtn(true);
      let data = headerData;
      if (headerData.file) {
        const formData = new FormData();
        formData.append("directory", "images/");
        formData.append("upload_file", headerData.file);
        const imgResult = await _upload_file(formData);
        if (imgResult.code == 200) {
          data["logo"] = imgResult.path;
        }
      }
      delete data.file;
      delete data.blob;
      data["menu_items"] = data.menu_items.map((item) => {
        return { title: item.title, link: item.link };
      });
      const result = await addHeader(data);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setHeaderData(result.data.header);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingBtn(false);
    }
  };
  useEffect(() => {
    getLandingPageData();
  }, []);
  if (loading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Header">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">Header</Typography>
        </Stack>
        <form action="" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Sign in Button Text"
                  value={headerData.sign_in_button.text}
                  onChange={(e) =>
                    setHeaderData((prev) => {
                      return {
                        ...prev,
                        sign_in_button: {
                          ...prev.sign_in_button,
                          text: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Sign in Button link"
                  value={headerData.sign_in_button.link}
                  onChange={(e) =>
                    setHeaderData((prev) => {
                      return {
                        ...prev,
                        sign_in_button: {
                          ...prev.sign_in_button,
                          link: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Get Started Button Text"
                  value={headerData.get_started_button.text}
                  onChange={(e) =>
                    setHeaderData((prev) => {
                      return {
                        ...prev,
                        get_started_button: {
                          ...prev.get_started_button,
                          text: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Get Started Button link"
                  value={headerData.get_started_button.link}
                  onChange={(e) =>
                    setHeaderData((prev) => {
                      return {
                        ...prev,
                        get_started_button: {
                          ...prev.get_started_button,
                          link: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {(headerData.blob || headerData.logo) && (
                <img
                  style={{
                    width: "17rem",
                    height: "9rem",
                    // marginLeft: "27rem",
                    marginTop: "1rem",
                  }}
                  src={
                    headerData.blob
                      ? headerData.blob
                      : s3baseUrl + headerData.logo
                  }
                  alt="preview"
                />
              )}

              <label htmlFor="icon-button-file">
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  value=""
                  name="logo"
                  onChange={(e) =>
                    setHeaderData((prev) => {
                      return {
                        ...prev,
                        file: e.target.files[0],
                        blob: URL.createObjectURL(e.target.files[0]),
                      };
                    })
                  }
                />
                <Button
                  aria-label="upload picture"
                  component="span"
                  className="upload-button mt-3 "
                >
                  {headerData.logo !== "" ? "Change Logo" : "Upload Logo"}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} mt={5}>
              <Button onClick={openAddItemDialog}>Add Menu Item</Button>
            </Grid>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Link</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {headerData?.menu_items?.map((val, i) => {
                    return (
                      <>
                        <TableRow key={i}>
                          <TableCell>{val.title}</TableCell>
                          <TableCell>{val.link}</TableCell>
                          <TableCell>
                            <Iconify
                              onClick={() => {
                                setEditIndex(i);
                                setIsEditing(true);
                                setNewMenuItem({
                                  title: val.title,
                                  link: val.link,
                                });
                                openAddItemDialog();
                              }}
                              style={{ color: "green", cursor: "pointer" }}
                              icon={"fa-regular:edit"}
                            />
                          </TableCell>
                          <TableCell>
                            <Iconify
                              onClick={() => {
                                setSelectedindex(i);
                                openDelete();
                              }}
                              style={{ color: "red", cursor: "pointer" }}
                              icon={"ic:baseline-delete"}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              <div className="text-end pb-4">
                <LoadingButton
                  loading={loadingBtn}
                  variant="contained"
                  type="submit"
                >
                  Submit
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Dialog
        open={addMenuitemDialogOpen}
        onClose={closeAddItemDialog}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>
              {isEditing ? "Edit Header Menu Items" : "Add Header Menu Items"}
            </p>
            <p onClick={closeAddItemDialog}>
              <Iconify icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid>
            <>
              <div>
                <form
                  className="gap-3 mt-3"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (isEditing) {
                      handleEditData();
                    } else {
                      handleAddData();
                    }
                  }}
                >
                  <TextField
                    label="title"
                    required
                    style={{ width: "48%" }}
                    onChange={(e) =>
                      setNewMenuItem((prev) => {
                        return {
                          ...prev,
                          title: e.target.value,
                        };
                      })
                    }
                    value={newMenuItem?.title}
                    name="title"
                  />
                  <TextField
                    label="link"
                    required
                    style={{ width: "48%", marginLeft: "15px" }}
                    onChange={(e) =>
                      setNewMenuItem((prev) => {
                        return {
                          ...prev,
                          link: e.target.value,
                        };
                      })
                    }
                    value={newMenuItem?.link}
                    name="link"
                  />
                  <div className="text-end mt-3">
                    <Button
                      variant="contained"
                      type="submit"
                      // onClick={(e) => HandleEditData(e)}
                    >
                      {isEditing ? "Edit" : "Add"}
                    </Button>
                  </div>
                </form>
              </div>
            </>
          </Grid>
        </DialogContent>
      </Dialog>
      <MuiDialog
        open={deleteLogoDialog}
        onToggle={closeDelete}
        title="Delete menu item"
        message="Are you sure you want to delete this menu item?"
        onAgree={confirmDelete}
      />
    </Page>
  );
}

export default Header;
