import { LoadingButton } from "@mui/lab";
import {
  Card,
  CircularProgress,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { capitalCase } from "change-case";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  _close_support_ticket,
  _delete_support_ticket,
  _get_support_tickets_list,
} from "src/DAL";
import {
  CircularLoader,
  Iconify,
  Label,
  MuiDialog,
  Page,
  Scrollbar,
  SearchNotFound,
} from "src/components";
import TableCustomHead from "src/components/TableCustomHead";
import TableToolbar from "src/components/TableToolbar";
import { useAppContext } from "src/hooks";
const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "subject", label: "Subject", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "comment_badge_count", label: "Unread Messages", alignRight: false },
  { id: "response_status", label: "Response Status", alignRight: false },
  { id: "ticket_status", label: "Ticket Status", alignRight: false },
  { id: "last_action_date", label: "Last Activity", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "" },
];
function SupportTickets() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { setBadge } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [tickets, setTickets] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [selectedTab, setSelectedTab] = useState("open");
  const [counts, setCounts] = useState({ open: 0, all: 0, closed: 0 });
  const [menuOpen, setMenuOpen] = useState(null);
  const [currentTicket, setCurrentTicket] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [closeDialogOpen, setCloseDialogOpen] = useState(false);
  const [deleteTicketLoading, setDeleteTicketLoading] = useState(false);
  const [closeTicketLoading, setCloseTicketLoading] = useState(false);
  const handleOpenMenu = (e) => {
    setMenuOpen(e.target);
  };
  const handleCloseMenu = () => {
    setMenuOpen(null);
    setCurrentTicket();
  };
  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };
  const openCloseDialog = () => {
    setCloseDialogOpen(true);
  };
  const closeCloseDialog = () => {
    setCloseDialogOpen(false);
  };
  const menuOptions = [
    {
      label: "View Details",
      clickHandler: () => {
        if (
          currentTicket.unread_count > 0 ||
          currentTicket.unread_users.includes(
            JSON.parse(localStorage.getItem("user")).user_id._id
          )
        ) {
          setBadge((prev) => {
            return {
              ...prev,
              support: prev.support - 1,
            };
          });
        }
        navigate(`/support/${currentTicket._id}`);
      },
    },
    {
      label: "Close Ticket",
      clickHandler: openCloseDialog,
    },
    { label: "Delete", clickHandler: openDeleteDialog },
  ];
  const getMenuOptions = () => {
    if (!currentTicket) {
      return [];
    } else if (currentTicket.ticket_status == 0) {
      return menuOptions;
    } else {
      return menuOptions.filter((option) => option.label !== "Close Ticket");
    }
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const CheckActivityTime = (last_action_date) => {
    var No_Response_Threshold = new Date();
    No_Response_Threshold.setDate(No_Response_Threshold.getDate() - 7);
    var last_action = new Date(last_action_date);

    if (
      No_Response_Threshold.getTime() >= last_action.getTime(last_action_date)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const filteredTickets = () => {
    if (!filterName) {
      return [...tickets];
    } else {
      return tickets?.filter((x) =>
        x.subject.toLowerCase().includes(filterName.toLowerCase())
      );
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getSupportTicketsList = async () => {
    try {
      setLoading(true);
      const result = await _get_support_tickets_list({ status: selectedTab });
      if (result.code == 200) {
        setTickets(result.support_ticket);
        setCounts({
          open: result.open_ticket_count,
          all: result.support_ticket_count,
          closed: result.close_ticket_count,
        });
      }
    } catch (error) {
      console.log(error, " catched while fetching support tickets list");
      enqueueSnackbar("Something went wrong", { varinat: "error" });
    } finally {
      setLoading(false);
    }
  };
  const deleteSupportTicket = async () => {
    try {
      setDeleteTicketLoading(true);
      const result = await _delete_support_ticket(currentTicket._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setTickets((prev) =>
          prev.filter((ticket) => ticket._id !== currentTicket._id)
        );
        setCounts((prev) => {
          return {
            open: currentTicket.ticket_status == 0 ? prev.open - 1 : prev.open,
            all: prev.all - 1,
            closed:
              currentTicket.ticket_status != 0 ? prev.closed - 1 : prev.closed,
          };
        });
        handleCloseMenu();
        closeDeleteDialog();
      }
    } catch (error) {
      console.log(error, " catched while deleting ticket");
      enqueueSnackbar("Something went wrong", { varinat: "error" });
    } finally {
      setDeleteTicketLoading(false);
    }
  };
  const closeSupportTicket = async () => {
    try {
      setCloseTicketLoading(true);
      const result = await _close_support_ticket(currentTicket._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        if (selectedTab == "open") {
          setTickets((prev) =>
            prev.filter((ticket) => ticket._id !== currentTicket._id)
          );
        } else {
          setTickets((prev) =>
            prev.map((ticket) => {
              if (ticket._id == currentTicket._id) {
                return { ...ticket, ticket_status: 1 };
              } else {
                return ticket;
              }
            })
          );
        }
        setCounts((prev) => {
          return {
            open: prev.open - 1,
            all: prev.all,
            closed: prev.closed + 1,
          };
        });
        handleCloseMenu();
        closeCloseDialog();
      }
    } catch (error) {
      console.log(error, " catched while closing ticket");
      enqueueSnackbar("Something went wrong", { varinat: "error" });
    } finally {
      setCloseTicketLoading(false);
    }
  };
  useEffect(() => {
    getSupportTicketsList();
  }, [selectedTab]);
  // if (loading) {
  //   return (
  //     <Page title="Support Tickets">
  //       <Container maxWidth="xl">
  //         <Stack
  //           direction="row"
  //           alignItems="center"
  //           justifyContent="space-between"
  //           className="mb-4"
  //         >
  //           <Typography variant="h4">Support Tickets</Typography>
  //         </Stack>
  //         <CircularLoader />
  //       </Container>
  //     </Page>
  //   );
  // }
  return (
    <Page title="Support Tickets">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="mb-4"
        >
          <Typography variant="h4">Support Tickets</Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-start"
          mb={3}
        >
          <div className="me-2 pointer">
            <Label
              onClick={() => setSelectedTab("open")}
              variant={selectedTab === "open" ? "ghost" : ""}
              sx={{ width: "100px", cursor: "pointer" }}
              color="info"
            >
              OPEN ({counts.open})
            </Label>
          </div>

          <div className="me-2 pointer">
            <Label
              onClick={() => setSelectedTab("closed")}
              variant={selectedTab === "closed" ? "ghost" : ""}
              sx={{ width: "100px", cursor: "pointer" }}
              color="error"
            >
              CLOSED ({counts.closed})
            </Label>
          </div>
          <div className="me-2 pointer">
            <Label
              onClick={() => setSelectedTab("all")}
              variant={selectedTab === "all" ? "ghost" : ""}
              sx={{ width: "100px", cursor: "pointer" }}
              color="success"
            >
              ALL ({counts.all})
            </Label>
          </div>
        </Stack>
        {loading && <CircularLoader />}
        {/* {tickets.length > 0 && ( */}
        {!loading && (
          <Card
            sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
          >
            <TableToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              moduleName="Support Tickets"
              placeholder="Search Support Tickets..."
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableCustomHead headLabel={TABLE_HEAD} />
                  {!loading && (
                    <TableBody>
                      {filteredTickets()
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const {
                            subject,
                            _id,
                            description,
                            response_status,
                            ticket_status,
                            createdAt,
                            comment_badge_count,
                            last_action_date,
                            category,
                            unread_count,
                            unread_users,
                          } = row;
                          return (
                            <TableRow
                              hover
                              key={_id}
                              selected={unread_users.includes(
                                JSON.parse(localStorage.getItem("user")).user_id
                                  ._id
                              )}
                            >
                              <TableCell>
                                {rowsPerPage * page + (index + 1)}
                              </TableCell>
                              <TableCell>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (
                                      unread_count > 0 ||
                                      unread_users.includes(
                                        JSON.parse(localStorage.getItem("user"))
                                          .user_id._id
                                      )
                                    ) {
                                      setBadge((prev) => {
                                        return {
                                          ...prev,
                                          support: prev.support - 1,
                                        };
                                      });
                                    }
                                    navigate(`/support/${_id}`);
                                  }}
                                >
                                  {subject.substring(0, 50)}
                                </div>
                              </TableCell>
                              <TableCell>
                                {description
                                  ? description.substring(0, 50)
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {capitalCase(category.replace("_", " "))}
                              </TableCell>
                              <TableCell>
                                <Label variant="filled" color="warning">
                                  {unread_count ? unread_count : "0"}
                                </Label>
                              </TableCell>
                              <TableCell>
                                {response_status === 0 && (
                                  <Label
                                    style={{ width: 70 }}
                                    variant="ghost"
                                    color="warning"
                                  >
                                    Waiting
                                  </Label>
                                )}

                                {response_status === 1 && (
                                  <Label
                                    style={{ width: 70 }}
                                    variant="ghost"
                                    color="success"
                                  >
                                    Replied
                                  </Label>
                                )}
                              </TableCell>
                              <TableCell>
                                {!CheckActivityTime(last_action_date) &&
                                  ticket_status === 0 && (
                                    <Label
                                      style={{ width: 70 }}
                                      variant="ghost"
                                      color="info"
                                    >
                                      OPEN
                                    </Label>
                                  )}

                                {ticket_status === 1 && (
                                  <Label
                                    style={{ width: 70 }}
                                    variant="ghost"
                                    color="error"
                                  >
                                    CLOSED
                                  </Label>
                                )}
                                {CheckActivityTime(last_action_date) &&
                                  ticket_status === 0 && (
                                    <Label
                                      // style={{ width: 110 }}
                                      variant="ghost"
                                      color="error"
                                    >
                                      Not Responding
                                    </Label>
                                  )}
                              </TableCell>
                              <TableCell>
                                {moment(last_action_date).fromNow()}
                              </TableCell>
                              <TableCell>
                                {moment(createdAt).format("LLL")}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={(e) => {
                                    setCurrentTicket(row);
                                    handleOpenMenu(e);
                                  }}
                                >
                                  <Iconify icon="fluent:more-vertical-16-filled" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                          // });
                        })}
                      {/* {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                      <Menu
                        open={Boolean(menuOpen)}
                        anchorEl={menuOpen}
                        onClose={handleCloseMenu}
                      >
                        {getMenuOptions().map((item) => (
                          <MenuItem onClick={item.clickHandler}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Menu>
                    </TableBody>
                  )}
                  {/* {loading && (
                  <TableBody>
                    <TableRow style={{ height: 60 }}>
                      <TableCell colSpan={10}>
                        <div className="d-flex justify-content-center ">
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
                  {filteredTickets().length === 0 && !loading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[50, 100, 150]}
              component="div"
              count={filteredTickets()?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
        {/* )} */}
      </Container>
      <MuiDialog
        open={deleteDialogOpen}
        onToggle={closeDeleteDialog}
        title="Delete Ticket"
        message="Are you sure you want to delete this support ticket"
        onAgree={deleteSupportTicket}
        loading={deleteTicketLoading}
      />
      <MuiDialog
        open={closeDialogOpen}
        onToggle={closeCloseDialog}
        title="Close Ticket"
        message="Are you sure you want to close this support ticket"
        onAgree={closeSupportTicket}
        loading={closeTicketLoading}
      />
    </Page>
  );
}

export default SupportTickets;
