import { invokeApi } from "src/utils";

export const _get_dashboard_stats = () => {
  const requestObj = {
    path: `api/admin/dashboard_stats`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
