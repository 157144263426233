import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { _get_editor_keys, _set_editor_keys } from "src/DAL/websiteSettings";
import { CircularLoader, Page } from "src/components";
import { useAppContext } from "src/hooks";

function EditorSettings() {
  const { keys, setKeys, keyLoading } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [clientKey, setClientKey] = useState(keys.client);
  const [adminKey, setAdminKey] = useState(keys.admin);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (clientKey === keys.client && adminKey === keys.admin) {
        enqueueSnackbar("No changes to update", { variant: "info" });
        setLoading(false);
        return;
      }
      const result = await _set_editor_keys({
        editor_keys: {
          admin: adminKey,
          client: clientKey,
        },
      });
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setKeys(result.editor_keys);
      }
    } catch (error) {
      console.error("Error while updating editor keys:", error);
      enqueueSnackbar("Failed to update keys. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setClientKey(keys.client);
    setAdminKey(keys.admin);
  }, [keys]);

  return (
    <Page title="Editor Settings">
      {keyLoading ? (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <CircularLoader />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <Typography variant="h4" gutterBottom>
              Editor Settings
            </Typography>
          </Container>
          <Container sx={{ my: 10 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Client Editor Key"
                      onChange={(e) => setClientKey(e.target.value)}
                      value={clientKey}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Admin Editor Key"
                      onChange={(e) => setAdminKey(e.target.value)}
                      value={adminKey}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <LoadingButton
                loading={loading}
                variant="contained"
                sx={{ mt: 2 }}
                type="submit"
              >
                Update
              </LoadingButton>
            </form>
          </Container>
        </>
      )}
    </Page>
  );
}

export default EditorSettings;
