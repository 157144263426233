// PaymentPlanCard.jsx
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Iconify } from "src/components";
import { Waves, WavesTop } from "src/assets";
import { LoadingButton } from "@mui/lab";

const PaymentPlanCard = ({ user, onClick, plan, buttonLoading }) => {
  console.log(user, "ascsiocasucilasc", plan);
  // const getButtonText = (user, plan) => {
  //   if (
  //     (user.subscription_plan_name == plan.subscription_plan_name &&
  //       user.subscription_status == "active") ||
  //     (plan.subscription_plan_name == "free" && user.subscription_status == "")
  //   ) {
  //     return "Current Plan";
  //   }
  //   if (
  //     user.subscription_plan_name == plan.subscription_plan_name &&
  //     user.subscription_status == "deleted"
  //   ) {
  //     return "Buy Again";
  //   }
  //   if (
  //     user.subscription_plan_name !== plan.subscription_plan_name &&
  //     user.subscription_plan_name == "advance"
  //   ) {
  //     return "Cannot downgrade";
  //   }
  //   if (
  //     user.subscription_plan_name == "basic" &&
  //     plan.subscription_plan_name == "free"
  //   ) {
  //     return "Cannot downgrade";
  //   }
  //   if (
  //     user.subscription_plan_name == "basic" &&
  //     plan.subscription_plan_name == "advance"
  //   ) {
  //     return "Select plan";
  //   }
  //   if (
  //     user.subscription_plan_name == "free" &&
  //     (plan.subscription_plan_name == "advance" ||
  //       plan.subscription_plan_name == "basic")
  //   ) {
  //     return "Select plan";
  //   }
  // };

  return (
    <div
      className="price-card"
      style={{
        backgroundImage: `url(${Waves})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        borderRadius: "5px !important",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
        height: "110%",
      }}
    >
      <div
        className="price-text mx-auto"
        style={{
          backgroundImage: `url(${WavesTop})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          //   backgroundPosition: "top",
          textAlign: "center",
          color: "white",
        }}
      >
        <h3 style={{ height: "70px" }}>
          <span className="mt-3">
            {plan?.currency_symbol ? plan?.currency_symbol : "$"}
          </span>
          {plan.price ? plan.price : "0"}
        </h3>
      </div>
      <h4 className="text-center">{plan?.name ? plan?.name : "-"}</h4>
      <div>
        <p className="pt-2 text-center" style={{ fontWeight: "500" }}>
          {plan?.short_description}
        </p>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: plan?.detailed_description,
        }}
      ></div>
      <div className="buy-btn d-flex justify-content-center">
        <LoadingButton
          variant="contained"
          // disabled={
          //   getButtonText(user, plan) == "Cannot downgrade" ||
          //   getButtonText(user, plan) == "Current Plan"
          // }
          loading={buttonLoading == plan?._id}
          onClick={() => onClick(plan)}
        >
          {/* {getButtonText(user, plan)} */}
          Assign Plan
        </LoadingButton>
      </div>
    </div>
  );
};

export default PaymentPlanCard;
