import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { addPowerfulWork, getLandingPage } from "src/DAL/landingPage";
import { CircularLoader, Page, RichEditor } from "src/components";

function PowerfulWork() {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [powerfullWorkData, setPowerfullWorkData] = useState();
  const [loading, setLoading] = useState(true);
  const getLandingPageData = async () => {
    try {
      const result = await getLandingPage();
      if (result.code == 200) {
        setPowerfullWorkData(result.data.powerful_work);
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadingBtn(true);
      const result = await addPowerfulWork(powerfullWorkData);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setPowerfullWorkData(result.data.powerful_work);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingBtn(false);
    }
  };
  useEffect(() => {
    getLandingPageData();
  }, []);
  if (loading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Powerful Work">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">Powerful Work</Typography>
        </Stack>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RichEditor
                value={powerfullWorkData.title}
                setValue={(val) =>
                  setPowerfullWorkData((prev) => {
                    return { ...prev, title: val };
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Button text"
                  value={powerfullWorkData.button.text}
                  onChange={(e) =>
                    setPowerfullWorkData((prev) => {
                      return {
                        ...prev,
                        button: { ...prev.button, text: e.target.value },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Button link"
                  value={powerfullWorkData.button.link}
                  onChange={(e) =>
                    setPowerfullWorkData((prev) => {
                      return {
                        ...prev,
                        button: { ...prev.button, link: e.target.value },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div className="text-end pb-4">
                <LoadingButton
                  loading={loadingBtn}
                  variant="contained"
                  type="submit"
                >
                  Submit
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
}

export default PowerfulWork;
