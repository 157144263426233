import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get_currency_list } from "src/DAL/currency";
import { _add_payment_plan, _edit_payment_plan } from "src/DAL/payment_plans";
import { CircularLoader, Iconify, Page, RichEditor } from "src/components";

function EditPaymentPlan() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    name: state?.name,
    price: state?.price,
    workspace_allow:
      state?.workspace_allow == "unlimited" ? "" : state?.workspace_allow,
    project_allow:
      state?.project_allow == "unlimited" ? "" : state?.project_allow,
    storage_allow: state?.storage_allow.replace(/[^\d]/g, ""),
    manager: "0",
    team_member_allow:
      state?.team_member_allow == "unlimited" ? "" : state?.team_member_allow,
    email_support:
      state?.email_support.toLowerCase() !== "no"
        ? "yes"
        : state?.email_support.toLowerCase(),
    client_allow: state?.client_allow == "unlimited" ? "" : state?.client_allow,
    plan_name: state?.subscription_plan_name,
    hours:
      state?.email_support !== "no"
        ? state?.email_support.replace(/[^\d]/g, "")
        : "",
    period:
      state?.email_support !== "no"
        ? state?.email_support
            .replace(/[\d\s]/g, "")
            .toLowerCase()
            .endsWith("weekly")
          ? "weekly"
          : "monthly"
        : "weekly",
    storage_unit: state?.storage_allow.replace(/[\d\s]/g, ""),
    workspace_limit:
      state?.workspace_allow !== "unlimited" ? "limited" : "unlimited",
    project_limit:
      state?.project_allow !== "unlimited" ? "limited" : "unlimited",
    team_limit:
      state?.team_member_allow !== "unlimited" ? "limited" : "unlimited",
    client_limit: state?.client_allow !== "unlimited" ? "limited" : "unlimited",
    status: state.status,
    plan_currency: state.plan_currency,
    type: state.type,
    payment_access:
      state.is_plan_free === false
        ? state.payment_access !== "free"
          ? state.payment_access
          : "one_time"
        : "one_time",
    grace_period_days: state.grace_period_days,
    trial_period_days: state.trial_period_days,
    initial_amount: state.initial_amount,
    time_period_interval: state?.time_period_interval,
    installment_amount: state.installment_amount,
    is_plan_free: state.is_plan_free,
    short_description: state.short_description,
    detailed_description: state.detailed_description,
    order: state?.order,
    plan_type: state?.is_plan_free === false ? state?.plan_type : "month",
    access_type: state?.access_type,
    with_card: 1,
    limited_days: state?.limited_days,
  });
  console.log(formInputs, "inputssss");
  console.log(state, "statestatestate");

  const handleChange = (e) => {
    setFormInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoader(true);
    try {
      if (formInputs?.detailed_description === "") {
        enqueueSnackbar("Long description is not allowed to be empty", {
          variant: "error",
        });
        return;
      }
      if (
        formInputs?.payment_access == "installment" &&
        Number(formInputs?.initial_amount) >= Number(formInputs?.price)
      ) {
        enqueueSnackbar(
          "The initial amount should be less than the total plan price ",
          { variant: "error" }
        );

        return;
      }

      if (
        formInputs?.payment_access == "installment" &&
        Number(formInputs.installment_amount) >=
          Number(formInputs.initial_amount)
      ) {
        enqueueSnackbar(
          "The installment amount should be less than the initial amount ",
          { variant: "error" }
        );
        return;
      }

      let req = {
        name: formInputs?.name,
        manager: formInputs?.manager,
        is_plan_free: formInputs?.is_plan_free,
        email_support:
          formInputs.email_support !== "no"
            ? formInputs.hours + " hours " + formInputs.period
            : formInputs.email_support,
        workspace_allow:
          formInputs.workspace_limit !== "unlimited"
            ? formInputs.workspace_allow
            : formInputs.workspace_limit,
        project_allow:
          formInputs.project_limit !== "unlimited"
            ? formInputs.project_allow
            : formInputs.project_limit,
        team_member_allow:
          formInputs.team_limit !== "unlimited"
            ? formInputs.team_member_allow
            : formInputs.team_limit,
        client_allow:
          formInputs.client_limit !== "unlimited"
            ? formInputs.client_allow
            : formInputs.client_limit,
        storage_allow: formInputs.storage_allow + formInputs.storage_unit,
        currency_symbol:
          currencyList.find(
            (item) => item.short_form === formInputs.plan_currency
          )?.symbol || "$",
        short_description: formInputs.short_description,
        detailed_description: formInputs.detailed_description,
        status: formInputs?.status,
        type: formInputs?.type,
        with_card: formInputs?.with_card,
        order: formInputs?.order,
        plan_id: state?._id,
      };
      if (
        formInputs?.is_plan_free === false &&
        formInputs.payment_access === "one_time"
      ) {
        req.access_type = formInputs?.access_type;
        req.plan_price = formInputs.plan_price;
        req.plan_currency = formInputs.plan_currency;
      }
      if (
        formInputs.payment_access == "one_time" &&
        formInputs.access_type === 0
      ) {
        req.limited_days = formInputs?.limited_days;
      }
      if (formInputs?.is_plan_free === false) {
        req.payment_access = formInputs?.payment_access;
      }
      if (
        formInputs.payment_access == "installment" ||
        formInputs.payment_access == "recurring_fixed" ||
        formInputs.payment_access == "recurring_basic"
      ) {
        req.trial_period_days = formInputs.trial_period_days;
      }
      req.grace_period_days = formInputs.grace_period_days;
      if (formInputs?.is_plan_free === true) {
        req.payment_access = formInputs.payment_access;
      }
      if (formInputs.is_plan_free === false) {
        req.price = formInputs.price;
        req.plan_currency = formInputs.plan_currency;
        req.payment_access = formInputs.payment_access;
        if (formInputs.payment_access == "one_time") {
          req.access_type = formInputs?.access_type;
          req.price = formInputs.price;
          req.plan_currency = formInputs.plan_currency;
        }
        if (formInputs.payment_access == "installment") {
          req.time_period_interval = formInputs.time_period_interval;
          req.plan_type = formInputs.plan_type ? formInputs.plan_type : "month";
          req.initial_amount = formInputs.initial_amount;
        }
        if (formInputs?.payment_access == "recurring_fixed") {
          req.time_period_interval = formInputs.time_period_interval;
          req.installment_amount = formInputs.installment_amount;
          req.plan_type = !!formInputs.plan_type
            ? formInputs.plan_type
            : "month";
        }
        if (formInputs?.payment_access == "recurring_basic") {
          req.plan_type = formInputs.plan_type ? formInputs.plan_type : "month";
        }
      }

      delete req.hours;
      delete req.period;
      delete req.workspace_limit;
      delete req.project_limit;
      delete req.team_limit;
      delete req.client_limit;
      delete req.storage_unit;

      console.log(req, "requesttttt");

      const result = await _edit_payment_plan(req);
      if (result.code == 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while adding payment plan");
    } finally {
      setButtonLoader(false);
    }
  };

  const handleGetCurrencyList = async () => {
    setIsLoading(true);
    const result = await get_currency_list();
    console.log(result, "result----");
    if (result.code == 200) {
      setCurrencyList([...result?.plan_currency_list]);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetCurrencyList();
  }, []);

  useEffect(() => {
    let totalValue = formInputs.price - formInputs.initial_amount;
    if (totalValue < 0 || formInputs.time_period_interval <= 0) {
      setFormInputs((values) => ({
        ...values,
        ["installment_amount"]: 0,
      }));
    } else {
      let installment = (totalValue / formInputs.time_period_interval).toFixed(
        1
      );
      setFormInputs((values) => ({
        ...values,
        ["installment_amount"]:
          !isNaN(installment) && installment !== "Infinity" ? installment : 0,
      }));
    }
  }, [
    formInputs.price,
    formInputs.time_period_interval,
    formInputs.initial_amount,
  ]);

  if (isLoading) {
    return (
      <div>
        <CircularLoader />
      </div>
    );
  }

  return (
    <Page title="Edit Plan">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="" mb={4.5}>
          <IconButton onClick={() => navigate(-1)}>
            <Iconify icon="ic:round-arrow-back" />
          </IconButton>
          <Typography variant="h4">Edit Plan</Typography>
        </Stack>
        <form onSubmit={handleSubmit}>
          <Typography className="mb-3" variant="h5">
            Plan Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  required
                  name="name"
                  label="Plan Name"
                  value={formInputs.name}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-label-status">
                  Plan Status
                </InputLabel>
                <Select
                  id="demo-simple-label-status"
                  label="Plan Status"
                  value={formInputs.status}
                  name="status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>In-Active</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-label">Plan Type</InputLabel>
                <Select
                  id="demo-simple-label"
                  label="Plan Type"
                  value={formInputs.is_plan_free}
                  name="is_plan_free"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Free</MenuItem>
                  <MenuItem value={false}>Paid</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-label">Type</InputLabel>
                <Select
                  id="demo-simple-label"
                  label="Type"
                  name="type"
                  value={formInputs.type}
                  onChange={handleChange}
                >
                  <MenuItem value={0}>Both</MenuItem>
                  <MenuItem value={1}>Website</MenuItem>
                  <MenuItem value={2}>Portal</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {formInputs.is_plan_free === false && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-label">Plan Payment</InputLabel>
                  <Select
                    id="demo-label"
                    label="Plan Payment"
                    value={
                      formInputs.is_plan_free === false
                        ? formInputs.payment_access !== "free"
                          ? formInputs.payment_access
                          : "one_time"
                        : "one_time"
                    }
                    name="payment_access"
                    onChange={handleChange}
                  >
                    <MenuItem value="one_time">One Time</MenuItem>
                    <MenuItem value="installment">Installments</MenuItem>
                    <MenuItem value="recurring_fixed">Recurring Fixed</MenuItem>
                    <MenuItem value="recurring_basic">Recurring Basic</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            {formInputs.is_plan_free === false && (
              <>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth required>
                    <InputLabel id="demo-label-currency">Currency</InputLabel>
                    <Select
                      id="demo-label"
                      label="Currency"
                      value={formInputs.plan_currency}
                      name="plan_currency"
                      onChange={handleChange}
                    >
                      {currencyList.map(
                        (item) =>
                          item.status && (
                            <MenuItem
                              value={item.short_form}
                            >{`${item.full_form} (${item.short_form})`}</MenuItem>
                          )
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      name="price"
                      label="Total Price"
                      value={formInputs.price}
                      onChange={(e) => {
                        let temp = e.target.value.replace(
                          /^[^\d]*(\d*\.?\d{0,2}).*$/,
                          "$1"
                        );
                        temp = temp.replace(/(\..*)\./g, "$1");
                        setFormInputs((prev) => {
                          return { ...prev, price: temp };
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
              </>
            )}

            {formInputs.payment_access == "installment" ? (
              <>
                {formInputs?.is_plan_free === false && (
                  <>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth required>
                        <InputLabel id="demo-simple-select-label1">
                          Plan Payment Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label1"
                          id="demo-simple-select"
                          name="plan_type"
                          value={
                            formInputs.plan_type == "free"
                              ? "month"
                              : formInputs.plan_type
                          }
                          label="Plan Payment Type"
                          onChange={handleChange}
                          required
                        >
                          <MenuItem value="month">Monthly</MenuItem>
                          <MenuItem value="year">Yearly</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <TextField
                          required
                          name="initial_amount"
                          label="Initial Amount"
                          value={formInputs.initial_amount}
                          onChange={(e) => {
                            let temp = e.target.value.replace(
                              /^[^\d]*(\d*\.?\d{0,2}).*$/,
                              "$1"
                            );
                            temp = temp.replace(/(\..*)\./g, "$1");
                            setFormInputs((prev) => {
                              return { ...prev, initial_amount: temp };
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <TextField
                          required
                          name="time_period_interval"
                          label="Recurring Time Period "
                          value={formInputs.time_period_interval}
                          onChange={(e) => {
                            let temp = e.target.value.replace(
                              /^[^\d]*(\d*\.?\d{0,2}).*$/,
                              "$1"
                            );
                            temp = temp.replace(/(\..*)\./g, "$1");
                            setFormInputs((prev) => {
                              return { ...prev, time_period_interval: temp };
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <TextField
                          required
                          name="installment_amount"
                          label="Installment Amount"
                          value={formInputs.installment_amount}
                          onChange={(e) => {
                            let temp = e.target.value.replace(
                              /^[^\d]*(\d*\.?\d{0,2}).*$/,
                              "$1"
                            );
                            temp = temp.replace(/(\..*)\./g, "$1");
                            setFormInputs((prev) => {
                              return { ...prev, installment_amount: temp };
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              ""
            )}

            {formInputs.payment_access == "recurring_fixed" ? (
              <>
                {formInputs?.is_plan_free === false && (
                  <>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth required>
                        <InputLabel id="demo-simple-select-label1">
                          Plan Payment Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label1"
                          id="demo-simple-select"
                          name="plan_type"
                          value={
                            formInputs.plan_type == "free"
                              ? "month"
                              : formInputs.plan_type
                          }
                          label="Plan Payment Type"
                          onChange={handleChange}
                          required
                        >
                          <MenuItem value="month">Monthly</MenuItem>
                          <MenuItem value="year">Yearly</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <TextField
                          required
                          name="time_period_interval"
                          label="Recurring Time Period "
                          value={formInputs.time_period_interval}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <TextField
                          required
                          name="installment_amount"
                          label="Installment Amount"
                          value={formInputs.installment_amount}
                          onChange={(e) => {
                            let temp = e.target.value.replace(
                              /^[^\d]*(\d*\.?\d{0,2}).*$/,
                              "$1"
                            );
                            temp = temp.replace(/(\..*)\./g, "$1");
                            setFormInputs((prev) => {
                              return { ...prev, installment_amount: temp };
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              ""
            )}

            {formInputs.payment_access == "recurring_basic" ? (
              <>
                {formInputs?.is_plan_free === false && (
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label1">
                        Plan Payment Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label1"
                        id="demo-simple-select"
                        name="plan_type"
                        value={
                          formInputs.plan_type == "free"
                            ? "month"
                            : formInputs.plan_type
                        }
                        label="Plan Payment Type"
                        onChange={handleChange}
                        required
                      >
                        <MenuItem value="month">Monthly</MenuItem>
                        <MenuItem value="year">Yearly</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </>
            ) : (
              ""
            )}

            {formInputs?.is_plan_free == false &&
              formInputs?.payment_access !== "one_time" && (
                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-basic"
                    label="Trial Period Days"
                    variant="outlined"
                    fullWidth
                    required
                    name="trial_period_days"
                    value={formInputs.trial_period_days}
                    onChange={(e) => {
                      let temp = e.target.value.replace(
                        /^[^\d]*(\d*\.?\d{0,2}).*$/,
                        "$1"
                      );
                      temp = temp.replace(/(\..*)\./g, "$1");
                      setFormInputs((prev) => {
                        return { ...prev, trial_period_days: temp };
                      });
                    }}
                  />
                </Grid>
              )}
            {/* 
            {(formInputs?.is_plan_free == false &&
              formInputs?.with_card == 1) ||
            (formInputs?.is_plan_free == false &&
              formInputs?.with_card == 2) ? (
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-basic"
                  label="Trial Period Days"
                  variant="outlined"
                  fullWidth
                  required
                  name="trial_period_days"
                  value={formInputs.trial_period_days}
                  onChange={(e) => {
                    let temp = e.target.value.replace(
                      /^[^\d]*(\d*\.?\d{0,2}).*$/,
                      "$1"
                    );
                    temp = temp.replace(/(\..*)\./g, "$1");
                    setFormInputs((prev) => {
                      return { ...prev, trial_period_days: temp };
                    });
                  }}
                />
              </Grid>
            ) : (
              ""
            )} */}

            {formInputs?.is_plan_free == false &&
              formInputs?.payment_access !== "one_time" && (
                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-basic"
                    label="Grace Period Days"
                    variant="outlined"
                    fullWidth
                    required
                    name="grace_period_days"
                    value={formInputs.grace_period_days}
                    onChange={(e) => {
                      let temp = e.target.value.replace(
                        /^[^\d]*(\d*\.?\d{0,2}).*$/,
                        "$1"
                      );
                      temp = temp.replace(/(\..*)\./g, "$1");
                      setFormInputs((prev) => {
                        return { ...prev, grace_period_days: temp };
                      });
                    }}
                  />
                </Grid>
              )}

            {formInputs.is_plan_free === false &&
              formInputs.payment_access == "one_time" && (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label1">
                        Access Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label1"
                        id="demo-simple-select"
                        name="access_type"
                        value={formInputs.access_type}
                        label="Access Type"
                        onChange={handleChange}
                        required
                      >
                        <MenuItem value={1}>Unlimited</MenuItem>
                        <MenuItem value={0}>Limited</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}

            {formInputs.is_plan_free === false &&
              formInputs.payment_access == "one_time" &&
              formInputs.access_type === 0 && (
                <>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-basic"
                      label="Number of days"
                      variant="outlined"
                      fullWidth
                      required
                      name="limited_days"
                      onWheel={(e) => e.target.blur()}
                      value={formInputs?.limited_days}
                      onChange={(e) => {
                        let temp = e.target.value.replace(
                          /^[^\d]*(\d*\.?\d{0,2}).*$/,
                          "$1"
                        );
                        temp = temp.replace(/(\..*)\./g, "$1");
                        setFormInputs((prev) => {
                          return {
                            ...prev,
                            limited_days: temp,
                          };
                        });
                      }}
                    />
                  </Grid>
                </>
              )}

            {formInputs?.is_plan_free === true && (
              <>
                {" "}
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label1">Card</InputLabel>
                    <Select
                      labelId="demo-simple-select-label1"
                      id="demo-simple-select"
                      name="with_card"
                      value={formInputs.with_card}
                      label="Card"
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value={1}>With Card</MenuItem>
                      <MenuItem value={2}>With Out Card</MenuItem>
                      {/* <MenuItem value={3}>None</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                onWheel={(e) => e.target.blur()}
                value={formInputs?.order}
                onChange={(e) => {
                  let temp = e.target.value.replace(
                    /^[^\d]*(\d*\.?\d{0,2}).*$/,
                    "$1"
                  );
                  temp = temp.replace(/(\..*)\./g, "$1");
                  setFormInputs((prev) => {
                    return {
                      ...prev,
                      order: temp,
                    };
                  });
                }}
              />
            </Grid>
          </Grid>

          <Typography className="mb-3 mt-3" variant="h5">
            Workspace Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-label">Workspaces Allowed</InputLabel>
                <Select
                  id="demo-label"
                  label="Workspaces Allowed"
                  value={formInputs.workspace_limit}
                  name="workspace_limit"
                  onChange={handleChange}
                >
                  <MenuItem value="unlimited">Unlimited</MenuItem>
                  <MenuItem value="limited">Limited</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {formInputs.workspace_limit !== "unlimited" && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    required
                    name="workspace_allow"
                    label="Workspace Limit"
                    value={formInputs.workspace_allow}
                    onChange={(e) => {
                      let temp = e.target.value.replace(/[^\d]/g, "");
                      temp = temp.replace(/^0+(?=[1-9])/, "");
                      setFormInputs((prev) => {
                        return { ...prev, workspace_allow: temp };
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="label">Projects Allowed</InputLabel>
                <Select
                  id="label"
                  label="Projects Allowed"
                  value={formInputs.project_limit}
                  name="project_limit"
                  onChange={handleChange}
                >
                  <MenuItem value="unlimited">Unlimited</MenuItem>
                  <MenuItem value="limited">Limited</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formInputs.project_limit !== "unlimited" && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    required
                    name="project_allow"
                    label="Projects Limit"
                    value={formInputs.project_allow}
                    onChange={(e) => {
                      let temp = e.target.value.replace(/[^\d]/g, "");
                      temp = temp.replace(/^0+(?=[1-9])/, "");
                      setFormInputs((prev) => {
                        return { ...prev, project_allow: temp };
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel id="unit">Storage Unit</InputLabel>
                <Select
                  id="unit"
                  value={formInputs.storage_unit}
                  name="storage_unit"
                  onChange={handleChange}
                  label="Storage Unit"
                >
                  <MenuItem value="mb">mb</MenuItem>
                  <MenuItem value="gb">gb</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <TextField
                  required
                  name="storage_allow"
                  label="Storage Allowed"
                  value={formInputs.storage_allow}
                  onChange={(e) => {
                    let temp = e.target.value.replace(/[^\d]/g, "");
                    temp = temp.replace(/^0+(?=[1-9])/, "");
                    setFormInputs((prev) => {
                      return { ...prev, storage_allow: temp };
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="asalabel">Team / Managers Allowed</InputLabel>
                <Select
                  id="asalabel"
                  label="Team / Managers Allowed"
                  value={formInputs.team_limit}
                  name="team_limit"
                  onChange={handleChange}
                >
                  <MenuItem value="unlimited">Unlimited</MenuItem>
                  <MenuItem value="limited">Limited</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formInputs.team_limit !== "unlimited" && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    required
                    name="team_member_allow"
                    label="Team / Managers Limit"
                    value={formInputs.team_member_allow}
                    onChange={(e) => {
                      let temp = e.target.value.replace(/[^\d]/g, "");
                      temp = temp.replace(/^0+(?=[1-9])/, "");
                      setFormInputs((prev) => {
                        return { ...prev, team_member_allow: temp };
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="asalabels">Clients Allowed</InputLabel>
                <Select
                  id="asalabels"
                  label="Clients Allowed"
                  value={formInputs.client_limit}
                  name="client_limit"
                  onChange={handleChange}
                >
                  <MenuItem value="unlimited">Unlimited</MenuItem>
                  <MenuItem value="limited">Limited</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formInputs.client_limit !== "unlimited" && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    required
                    name="client_allow"
                    label="Clients Allowed"
                    value={formInputs.client_allow}
                    onChange={(e) => {
                      let temp = e.target.value.replace(/[^\d]/g, "");
                      temp = temp.replace(/^0+(?=[1-9])/, "");
                      setFormInputs((prev) => {
                        return { ...prev, client_allow: temp };
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Email Support
                </InputLabel>
                <Select
                  id="demo-simple-select-label"
                  label="Email Support"
                  value={formInputs.email_support}
                  name="email_support"
                  onChange={handleChange}
                >
                  <MenuItem value="no">No</MenuItem>
                  <MenuItem value="yes">Yes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formInputs.email_support == "yes" && (
              <>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      name="hours"
                      label="Support Time (hours)"
                      value={formInputs.hours}
                      onChange={(e) => {
                        let temp = e.target.value.replace(/[^\d]/g, "");
                        temp = temp.replace(/^0+(?=[1-9])/, "");
                        setFormInputs((prev) => {
                          return { ...prev, hours: temp };
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-select-label">Time Period</InputLabel>
                    <Select
                      id="demo-select-label"
                      label="Time Period"
                      value={formInputs.period}
                      name="period"
                      onChange={handleChange}
                    >
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>

          <Typography className="mb-3 mt-3" variant="h5">
            Short Description
          </Typography>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                required
                multiline
                rows={5}
                name="short_description"
                label="Short Description"
                value={formInputs.short_description}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>

          <Typography className="mb-3 mt-3" variant="h5">
            Long Description*
          </Typography>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <RichEditor
                value={formInputs.detailed_description}
                placeHolder="Long Description*"
                setValue={(val) =>
                  setFormInputs((prev) => {
                    console.log({ val });
                    return { ...prev, detailed_description: val };
                  })
                }
              />
            </FormControl>
          </Grid>
          <div className="text-lg-end mt-5 mb-5">
            <LoadingButton
              loading={buttonLoader}
              // sx={{ mt: 2 }}
              variant="contained"
              type="submit"
            >
              Update Plan
            </LoadingButton>
          </div>
        </form>
      </Container>
    </Page>
  );
}

export default EditPaymentPlan;
