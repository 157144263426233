// component
import { useAppContext } from "src/hooks";
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const navConfig = () => {
  const { badge } = useAppContext();
  return [
    {
      title: "dashboard",
      path: "/dashboard",
      icon: getIcon("eva:pie-chart-2-fill"),
      /* "children" key can be added in these objects, example:children:[{...}] */
    },
    {
      title: "users",
      path: "/users",
      icon: getIcon("fe:users"),
    },
    {
      title: "transactions",
      path: "/transactions",
      icon: getIcon("iconoir:receive-dollars"),
    },
    {
      title: "payment plans",
      path: "/plans",
      icon: getIcon("solar:cash-out-bold"),
    },
    {
      title: "Currency Configuration",
      path: "/currency-configuration",
      icon: getIcon("formkit:multicurrency"),
    },
    {
      title: "Landing Page Settings",
      path: "/landing-page",
      icon: getIcon("gg:website"),
      children: [
        {
          title: "header",
          path: "/landing-page/header",
          // icon: getIcon("solar:cash-out-bold"),
        },
        {
          title: "banner",
          path: "/landing-page/banner",
          // icon: getIcon("solar:cash-out-bold"),
        },
        {
          title: "brand logo",
          path: "/landing-page/brand-logo",
          // icon: getIcon("solar:cash-out-bold"),
        },
        {
          title: "drive efficiency",
          path: "/landing-page/drive-efficiency",
          // icon: getIcon("solar:cash-out-bold"),
        },
        {
          title: "transform way",
          path: "/landing-page/transform-way",
          // icon: getIcon("solar:cash-out-bold"),
        },
        {
          title: "work management",
          path: "/landing-page/work-management",
          // icon: getIcon("solar:cash-out-bold"),
        },
        {
          title: "start projects",
          path: "/landing-page/start-projects",
          // icon: getIcon("solar:cash-out-bold"),
        },
        {
          title: "ready solutions",
          path: "/landing-page/ready-solutions",
          // icon: getIcon("solar:cash-out-bold"),
        },
        {
          title: "catch up",
          path: "/landing-page/catch-up",
          // icon: getIcon("solar:cash-out-bold"),
        },
        {
          title: "stories",
          path: "/landing-page/stories",
          // icon: getIcon("solar:cash-out-bold"),
        },
        {
          title: "mobile app",
          path: "/landing-page/mobile-app",
          // icon: getIcon("solar:cash-out-bold"),
        },
        {
          title: "powerful work",
          path: "/landing-page/powerfull-work",
          // icon: getIcon("solar:cash-out-bold"),
        },
        {
          title: "subscriber data",
          path: "/landing-page/subscriber-data",
          // icon: getIcon("solar:cash-out-bold"),
        },
        {
          title: "settings",
          path: "/landing-page/settings",
          // icon: getIcon("solar:cash-out-bold"),
        },
        {
          title: "footer",
          path: "/landing-page/footer",
          // icon: getIcon("solar:cash-out-bold"),
        },
      ],
    },
    {
      title: "website Settings",
      path: "settings",
      icon: getIcon("ic:round-settings"),
      children: [
        {
          title: "Stripe Settings",
          path: "/settings/stripe-settings",
        },
        // {
        //   title: "Settings",
        //   path: "/settings/website-settings",
        // },
        {
          title: "Editor Settings",
          path: "/settings/editor-settings",
        },
      ],
    },
    {
      title: "Support",
      path: "/support",
      icon: getIcon("fluent:person-support-24-regular"),
      badge: badge.support > 0,
      badgeContent: badge.support,
    },
    /* example: collapsible sidebar routes
    {
      title: "parent",
      path: "/parent",
      icon: getIcon("fe:users"),
    children: [
        {
            title: "child1",
        path: "/parent/child1",
        icon: getIcon("fe:users"),
      },
      {
        title: "child2",
        path: "/parent/child2",
        icon: getIcon("fe:users"),
      },
    ],
  },
  */
  ];
};

export default navConfig;
